<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document-callback'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('회원잔액(콜백)'),
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'balance/check',
      callbackUrl: this.$store.getters['auth/user'].transferCallbackUrl.balance,
      response: '',
      body: {
        username: { type: 'text', value: 'user1', desc: this.$t('text.api.username_desc'), required: true, length: '1~21' },
      }
    };
  },
  mounted() {
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }

     */
    const host = process.env.VUE_APP_API_SERVER_URL
    this.apiUrl = host + 'balance/check'

    // 전송값 설정
    this.response ='----------------------------------------------------------------------------\n' +
        '[사이트 콜백 수신값 - 응답이 필요없습니다.]\n' +
        '----------------------------------------------------------------------------\n' +
        '{\n' +
        ' "apiKey": "XXXXXXXXXXXXXXX", \t\t\t\t// API에서 부여 받은 Api Key\n' +
        ' "username": "site9080", \t\t\t\t// 사이트 회원 아이디\n' +
        ' "transactionKey": "53f3adf03f5b5b41fd589f7ce5e556d1", \t// 거래코드\n' +
        ' "type": "win", \t\t\t\t\t// 잔액 변경 유형(deposit, withdraw, bet, win, draw, lose, cancel)\n' +
        ' "vendorKey": "pragmatic_slot", \t\t\t// 게임사 코드\n' +
        ' "vendorName": "프라그마틱 슬롯", \t\t\t\t// 게임사 이름\n' +
        ' "gameKey": "vs20doghouse", \t\t\t\t// 게임코드\n' +
        ' "gameName": "The Dog House", \t\t\t\t// 게임 이름\n' +
        ' "msg": "결과(3000/101,000)", \t\t\t\t// 메세지\n' +
        ' "balance": 101000, \t\t\t\t\t// 잔액\n' +
        ' "amount": 3000 \t\t\t\t\t// 변경된 금액\n' +
        '}'
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title"><span class="badge bg-success">콜백 URL</span> {{ callbackUrl }}</div>
            <div class="card-title-desc">{{ $t('text.api_user_balance_callback_desc')}}</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="false" :require-ip-address="false" :require-request-key="false" :method="'run2'" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
